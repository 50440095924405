@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
}

.paypal-payment-status {
  padding: 12px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
}

.paypal-payment-status .title {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #0f0742;
  margin-top: 12px;
  margin-bottom: 12px;
}

.paypal-payment-status .subtitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #667085;
  margin-top: 12px;
  margin-bottom: 12px;
}

.paypal-payment-status .section-title {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #0f0742;
}

.paypal-payment-status .products-card {
  padding: 12px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #e4e7ec;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 18px;
}

.paypal-payment-status .product-image {
  object-fit: contain;
  width: 100%;
  height: 100%;
  border: 1px solid #e4e7ec;
  border-radius: 12px;
}

.paypal-payment-status .product-title {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #0f0742;
}

.paypal-payment-status .product-subtitle {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;

  color: #98a2b3;
}

.paypal-payment-status .product-price {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 22px;

  color: #98a2b3;
}

.paypal-payment-status .product-price-number {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 11px;

  text-decoration-line: line-through;

  color: #98a2b3;
}

.paypal-payment-status .product-price-number .final-price {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 11px;
  text-decoration-line: none;
  color: #0f0742;
}

.paypal-payment-status .product-discount {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px;
  background: #fef3f2;
  border-radius: 100px;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 11px;
  color: #f04438;
}

.paypal-payment-status .product-details p {
  text-align: left;
}

.paypal-payment-status .product-item {
  padding-bottom: 12px;
  border-bottom: 1px solid #e4e7ec;
}

.paypal-payment-status .order-details {
  background: #ffffff;
  border: 1px solid #e4e7ec;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 18px;
  padding: 20px;
}

.paypal-payment-status .order-details .title {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  color: #0f0742;
}
.paypal-payment-status .order-details .value {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: #0f0742;
}

.paypal-payment-status .order-details.sub {
  border: unset;
  box-shadow: unset;
}

.paypal-payment-status .total-container {
  padding: 20px;
  border-top: 1px solid #e4e7ec;
}

.paypal-payment-status .total {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  color: #0f0742;
}

.paypal-payment-status .total.value {
  text-align: right;
}

.paypal-payment-status button {
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 100px;

  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #344054;
}

.paypal-payment-status .button-container {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
}
